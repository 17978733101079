import { Controller } from "@hotwired/stimulus";

export class ModalController extends Controller {
	static targets = ["modal", "confirmBtn", "title", "message"];

	connect() {
		this.element[this.identifier] = this;
	}

	open(evt) {
		document.body.classList.add("overflow-hidden");
		this.modalTarget.showModal();
	}

	// Opens the confirmation modal.
	openConfirm(evt) {
		// Applying data
		evt.preventDefault();
		document.body.classList.add("overflow-hidden");

		const target = evt.target;
		const name = target.dataset.name || "";
		let method = "";

		if (target.dataset.twmethod) {
			method = target.dataset.twmethod.toUpperCase();
		} else if (target.dataset.method) {
			method = target.dataset.method.toUpperCase();
		}		

		const actionClass = method === "DELETE" ? "tw-btn-danger" : "tw-btn-primary";
		const type        = name.startsWith("bulk-") ? "BULK" : "SINGLE";

		this.messageTarget.innerHTML = target.dataset.confirm || target.dataset.message;
		this.titleTarget.innerText   = target.dataset.title;

		this.confirmBtnTarget.innerText      = target.dataset.button || "Confirm";
		this.confirmBtnTarget.dataset.method = method;
		this.confirmBtnTarget.dataset.type   = type;
		this.confirmBtnTarget.dataset.link   = target.href || target.dataset.url;
		this.confirmBtnTarget.dataset.action = "components--modal#confirm";
		this.confirmBtnTarget.classList      = "";
		this.confirmBtnTarget.classList.add("tw-btn", "ml-2", actionClass);

		if (target.dataset.bulkTarget !== undefined) {
			this.confirmBtnTarget.dataset.bulkTarget = target.dataset.bulkTarget;
		}

		// TODO: for backward compatibility, this should not be part of this controller
		if (name === "bulk-prompt") {
			this.messageTarget.classList.add("hidden");

			const formContent = document.getElementById(`${target.dataset.modalTarget}-modal`);
			const form        = document.createElement("form");
			form.method       = method;
			form.action       = target.href || target.dataset.url;
			form.innerHTML    = formContent;
			form.appendChild(formContent);
			this.messageTarget.insertAdjacentElement("afterend", form);
		} else {
			this.messageTarget.classList.remove("hidden");
			const form = this.element.querySelector("form");
			if (form != undefined) {
				form.remove();
			}
		}

		this.modalTarget.showModal();
	}

	openCancel({target}) {
		// Applying data
		this.messageTarget.innerHTML = "You have not saved changes. If you go back without saving changes, all information you entered will be removed. <br> Do you want to go back without saving changes?";
		this.titleTarget.innerText   = "Discard Changes";

		this.confirmBtnTarget.innerText      = "Discard Changes";
		this.confirmBtnTarget.dataset.method = "GET";
		this.confirmBtnTarget.dataset.link   = target.href;
		this.confirmBtnTarget.dataset.action = "components--modal#confirm";
		this.confirmBtnTarget.dataset.target = "_self";
		this.confirmBtnTarget.classList      = "";
		this.confirmBtnTarget.classList.add("tw-btn", "ml-2", "tw-btn-primary");

		this.modalTarget.showModal();
	}

	confirm({target}) {
		const confirmBtn = target;
		const modal      = target.closest(".tw-modal");

		const form = findOrCreateForm(modal, confirmBtn);
		if (confirmBtn.dataset.type === "BULK") {
			const selections = getBulkData("[data-controller='bulk-selection'] tbody"); // Hardcoded target
			form.append(...selections);
		}

		document.body.appendChild(form);
		form.submit();

		this.close();
	}

	close() {
		document.body.classList.remove("overflow-hidden");
		this.modalTarget.close();
	}
}

/**
 * returns a list of inputs elements.
 * @param {String} target - is the element where the selections are located, normally this will be a <table> element.
 * @returns {NodeList[]} The list of inputs selected.
 */
function getBulkData(target) {
	const inputs = [];
	const table  = document.querySelector(target);

	table.querySelectorAll("input:checked").forEach(input => {
		const formInput = document.createElement("input");
		formInput.setAttribute("name", input.dataset.name);
		formInput.setAttribute("value", input.dataset.value);

		inputs.push(formInput);
	});

	return inputs;
}

/**
 * returns a new or existent form tag element based on the modal target.
 * @param {HTMLElement} modalTarget - is the modal.
 * @param {HTMLElement} target - is the main element that will trigger the form.
 * @returns {HTMLElement} - is the form element.
 */
function findOrCreateForm(modalTarget, target) {
	const modalForm = modalTarget.querySelector("form");
	if (modalForm !== null) {
		return modalForm;
	}

	let [formMethod, formTarget] = ["POST", "_self"];
	if (target.dataset.method === "GET") {
		formMethod = "GET";
		formTarget = target.dataset.target || "_blank";
	}

	const form  = document.createElement("form");
	form.method = formMethod;
	form.target = formTarget;
	form.action = target.dataset.link;
	form.classList.add("hidden");

	if (formMethod !== "GET") {
		const method = document.createElement("input");
		method.type  = "hidden";
		method.name  = "_method";
		method.value = target.dataset.method;
		form.appendChild(method);

		const csrf = document.createElement("input");
		csrf.type  = "hidden";
		csrf.name  = getCSRFParam();
		csrf.value = getCSRFToken();
		form.appendChild(csrf);
	}

	return form;
}

function getCSRFToken() {
	let token = document.querySelector(`[name="csrf-token"]`);
	return token !== undefined ? token.content : "";
}

function getCSRFParam() {
	let param = document.querySelector(`[name="csrf-param"]`);
	return param !== undefined ? param.content : "";
}
