import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";

export default class DateRangeController extends Controller {
    static targets = ["from", "until"];
    static values = {
        minDate: String,
        maxDate: String,
    }

    initialize() {
        this.element[this.identifier] = this;
    }

    connect() {
        this.instance = flatpickr(this.fromTarget, {
            dateFormat: "m/d/Y",
            minDate: this.minDate,
            maxDate: this.maxDate,
            plugins: [new rangePlugin({ input: this.untilTarget })]
        });
    }

    /**
     * Returns the max date value
     */
    get maxDate() {
        let max = this.maxDateValue;
        if (max == "now()") {
            max = new Date()
        }

        return max
    }

    /**
     * Returns the min date value
     */
    get minDate() {
        let min = this.minDateValue;
        if (min == "now()") {
            min = new Date()
        }

        return min
    }
}
