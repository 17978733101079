import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import Pluralize from "pluralize";

export default class extends Controller {
	static targets = [
		"period", "date", "timeCol", "targetSelection",
		"selectedCourses", "totalCoursesSelected",
		"userPluralize", "coursePluralize", "totalUsersAssigned",
		"userSelection", "companySelection",
	];

	static values = {
		companies: Number,
		users: Number,
		assets: Number,
	};

	initialize() {
		this._initObserver();
		this._initExternalLibraries();

		if (this.hasUserSelectionTarget) {
			this.changeUserSelection();
		}

		if (this.hasCompanySelectionTarget) {
			this.changeCompanySelection();
		}
	}

	changeUserSelection() {
		const allUsers        = document.getElementById("AllUsersSelected");
		const usersList       = document.getElementById("UsersList");
		const usersTable      = document.getElementById("userList");
		const finishButton = document.querySelector("#FinishUserStep")
		const targetSelection = this.targetSelectionTargets.filter(input => input.checked)[0];

		if (targetSelection.id === "AllUsers") {
			usersList.classList.add("d-none");
			usersTable.classList.add("d-none");
			allUsers.classList.remove("d-none");
			finishButton.classList.remove("d-none");
		}

		if (targetSelection.id === "CustomUsers") {	
			allUsers.classList.add("d-none");
			usersTable.classList.remove("d-none");
			usersList.classList.remove("d-none");

			parseInt(document.querySelector("#CompanySelectedUsers").innerText) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
		}
	}

	changeCompanySelection() {
		Promise.resolve().then(() => {
			const targetCompanies = document.getElementById("target-companies");
			const controller      = targetCompanies["target-companies"];

			targetCompanies.addEventListener("targetcompanies.change", _ => {
				const allCompanies = document.querySelector("#all-companies")
				const finishButton = document.getElementById("FinishUserStep");

				this.usersValue     = controller.totalUsersCount;
				this.companiesValue = controller.totalCompaniesCount;

				if (allCompanies.checked) {
					finishButton.classList.remove("d-none");
				} else {
					parseInt(controller.totalCompaniesCount) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
				}
			});
		});
	}

	finishUser() {
		let usersCount        = 0;
		const targetSelection = this.targetSelectionTargets.filter(input => input.checked)[0].value;

		if (targetSelection === "all") {
			usersCount = document.getElementById("AllUsersCount").innerText;
		}

		if (targetSelection === "custom") {
			usersCount = document.querySelector("#CompanySelectedUsers").innerText;
		}

		this.usersValue                                                            = usersCount;
		const text                                                                 = `<p><span class='font-strong'>${usersCount}</span> users selected.</p>`;
		document.getElementById("UserResult").querySelector(".col-md-9").innerHTML = text;
		this.totalUsersAssignedTarget.innerText                   = usersCount;
		this.shouldPluralize(this.userPluralizeTargets, usersCount);
	}

	finishCompany() {
		const targetCompanies = document.getElementById("target-companies");
		const controller      = targetCompanies["target-companies"];

		this.usersValue     = controller.totalUsersCount;
		this.totalUsersAssignedTarget.innerText = controller.totalUsersCount;

		const text = `<p><span class='font-strong'>${this.companiesValue}</span> companies selected.</p>`;

		document.getElementById("CompanyResult").querySelector(".col-md-9").innerHTML = text;
	}

	finishAssets() {
		let assets        = [];
		const assetsItems = Array.from(document.querySelectorAll(".course-list .list-group-item"));
		assetsItems.forEach(item => {
			let duedate = item.querySelector("input.flatpickr").value || "<i>No Due Date</i>";

			const template = `
            <div class='row'>
                <div class='col-4 course-name b-r px-0'>
                    <img class='mr-2' src='/assets/images/icon/training_preview.svg/'>
                    <span>${item.querySelector("#course-template-name").textContent}</span>
                </div>
                <div class='col-6 course-duedate'><strong class='font-strong'>Due Date: </strong> ${duedate}</div>
            </div>
            <hr>
            `;
			assets.push(template);
		});

		const assetsCount                                                            = assetsItems.length;
		const text                                                                   = `
        <p class='mb-2'><span class='font-strong'>${assetsCount}</span> assets selected.</p>
        <div class='course-selection-list'>
            ${assets.join("")}
        </div>
        `;
		document.getElementById("AssetsResult").querySelector(".col-md-9").innerHTML = text;
		this.assetsValue                                                             = assetsCount;
	}

	toggleTimeInputs(e) {
		e.target.value === "immediately" ? this.timeColTarget.classList.add("d-none") : this.timeColTarget.classList.remove("d-none");
	}

	_initExternalLibraries() {
		new Choices(this.periodTarget, {
			resetScrollPosition: false,
			sortFn: function (a, b) {
				return parseInt(a.value) - parseInt(b.value);
			},
		});

		flatpickr(this.dateTarget, {
			dateFormat: "m/d/Y",
			enableTime: false,
			minDate: new Date(),
			onChange: (selectedDates, dateStr, instance) => {
				const dates = document.querySelectorAll(".flatpickr-course");
				const dates2 = document.querySelectorAll(".course-duedate");

				dates.forEach(date => {
					date._flatpickr.set("minDate", dateStr);
				});

				dates2.forEach(date => {
					// If date is lower than the selected date, set it to the selected date
					if (new Date(date.innerText) < new Date(dateStr)) {
						date.innerHTML = `<strong class='font-strong'>Due Date: </strong><i>No Due Date</i></div>`;
					}
				});
			},
		});
	}

	_initObserver() {
		const userCountElement    = document.querySelector("#CompanySelectedUsers");
		const coursesCountElement = document.querySelector("#TotalCoursesSelected");

		// Courses Count Observer
		const coursesBtnObserver = new MutationObserver(() => {
			const finishButton = document.querySelector("#FinishAssetsStep");
			const count        = coursesCountElement.innerText;

			parseInt(count) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
		});

		// Users Count Observer
		const buttonStateObserver = new MutationObserver(() => {
			const finishButton = document.getElementById("FinishUserStep");
			const count        = userCountElement.innerText;

			parseInt(count) > 0 ? finishButton.classList.remove("d-none") : finishButton.classList.add("d-none");
		});

		coursesBtnObserver.observe(coursesCountElement, {childList: true});
		buttonStateObserver.observe(userCountElement, {childList: true});
		buttonStateObserver.observe(this.totalCoursesSelectedTarget, {childList: true});
	}

	shouldPluralize(targets, count) {
		targets.forEach(word => word.innerText = Pluralize(word.innerText, count));
	}

	getDataTables(target) {
		return this.application.getControllerForElementAndIdentifier(target, "datatable");
	}

	assetsValueChanged(value, previousValue) {
		this.totalCoursesSelectedTarget.innerText = value;
		this.shouldPluralize(this.coursePluralizeTargets, value);
	}
}
