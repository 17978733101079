import Tagify from "@yaireo/tagify";
import { Controller } from "@hotwired/stimulus";

export default class ChoiceController extends Controller {
	static values = {
		maxTags: {type: Number, default: Infinity},
		options: String,
	};

	connect() {
		this.element[this.identifier] = this;
	}

	initialize() {
		new Tagify(this.element, {
			whitelist: this.optionsValue.split(","),
			dropdown: {
				closeOnSelect: false,
				enabled: 0, // always opens dropdown when input gets focus
			},
			editTags: false,
			maxTags: this.maxTagsValue,
			originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(","),
		});
	}
}
